import { resources, standardResources } from "supabase/drizzle/schema";
import { z } from "zod";

export type SelectResource = typeof resources.$inferSelect;
export type InsertResource = typeof resources.$inferInsert;
export type StandardResource = typeof standardResources.$inferSelect;

// Base content schemas for each type
const markdownContentSchema = z.object({
  type: z.literal("markdown"),
  content: z.string().min(1, "Content is required"),
});

const reactComponentContentSchema = z.object({
  type: z.literal("react-component"),
  content: z.string().min(1, "Content is required"),
});

// Combined resource content schema
export const resourceContentSchema = z.discriminatedUnion("type", [
  markdownContentSchema,
  reactComponentContentSchema,
]);

// Inferred types
export type ResourceContent = z.infer<typeof resourceContentSchema>;

export interface UpdateResource extends Omit<InsertResource, "id"> {
  id: number;
}
