import { RawMessageStreamEvent } from "@anthropic-ai/sdk/resources/index.mjs";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Message } from "modules/claude/claude.repository";
import { AssignmentQuestion } from "modules/questions/question.types";

export type ClaudeEvent = {
  type: string;
  index?: number;
  delta?: {
    type: string;
    text?: string;
    partial_json?: string;
  };
};

export type StreamCallbacks = {
  onEvent: (event: ClaudeEvent) => void;
  onFinished?: () => void;
};

export async function streamQuestionEditResponse({
  messages,
  question,
  onEvent,
  onFinished,
}: {
  messages: Message[];
  question: AssignmentQuestion["question"];
  onEvent: (event: RawMessageStreamEvent) => void;
  onFinished?: () => void;
}) {
  try {
    await fetchEventSource("/api/question-assistant", {
      method: "POST",
      body: JSON.stringify({ messages, question }),
      async onopen(response) {
        if (
          response.ok &&
          response.headers.get("content-type")?.includes("text/event-stream")
        ) {
          return;
        }
        throw new Error(
          `Stream connection failed with status ${response.status}`,
        );
      },
      onmessage(ev) {
        try {
          const event = JSON.parse(ev.data);
          onEvent(event);
        } catch (e) {
          console.error("Error parsing SSE data:", e, "Raw data:", ev.data);
          throw e;
        }
      },
      onclose() {
        onFinished?.();
      },
      onerror(err) {
        console.error("Stream connection error:", err);
        throw err;
      },
      openWhenHidden: true,
    });
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown stream error";
    console.error("Claude stream error:", errorMessage);
    throw error;
  }
}
