import type { Attachment } from "@ai-sdk/ui-utils";
import { Box } from "@mantine/core";
import { MutableRefObject } from "react";
import AISdkAssistant from "../AISdkAssistant/AISdkAssistant";
import AssignmentAssignmentsSearch from "./AssignmentAssignmentsSearch";
import AssignmentQuestionsSearch from "./AssignmentQuestionsSearch";

export type PanelContentType =
  | "ai-assistant"
  | "question-search"
  | "assignment-search";

type PanelContentProps = {
  type: PanelContentType;
  assignmentContext: { assignment: string };
  resetChatRef: MutableRefObject<(() => void) | undefined>;
  initialInput?: string; // Optional prop for the initial input from the landing page
  initialAttachments?: Attachment[]; // Optional prop for initial attachments
};

export default function PanelContent({
  type,
  assignmentContext,
  resetChatRef,
  initialInput,
  initialAttachments = [],
}: PanelContentProps) {
  // Instead of conditional rendering with a switch statement,
  // we'll render both components but show/hide based on the active type
  return (
    <>
      {/* AI Assistant Panel - always mounted but conditionally displayed */}
      <Box
        className="h-full"
        style={{ display: type === "ai-assistant" ? "block" : "none" }}
      >
        <AISdkAssistant
          configId="assignmentAssistant"
          emptyStateMessage="What questions would you like to create?"
          resetChatRef={resetChatRef}
          initialInput={initialInput}
          initialAttachments={initialAttachments}
          editorContext={{
            ...assignmentContext,
          }}
        />
      </Box>

      {/* Question Search Panel - always mounted but conditionally displayed */}
      <Box
        className="h-full overflow-hidden"
        style={{ display: type === "question-search" ? "block" : "none" }}
      >
        <AssignmentQuestionsSearch />
      </Box>

      {/* Assignment Search Panel - always mounted but conditionally displayed */}
      <Box
        className="h-full overflow-hidden"
        style={{ display: type === "assignment-search" ? "block" : "none" }}
      >
        <AssignmentAssignmentsSearch />
      </Box>
    </>
  );
}
