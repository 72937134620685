import { Anthropic } from "@anthropic-ai/sdk";
import { Box } from "@mantine/core";
import { questionContentSchema } from "modules/questions/question.types";
import { TypeOf } from "zod";
import MarkdownDisplay from "~/components/Markdown/MarkdownDisplay";
import { UpdatedQuestionArtifactDisplay } from "./AIUpdatedQuestionDisplay";
import { GeneratedArtifactDisplay } from "./GeneratedArtifactDisplay";

interface MessageDisplayProps {
  message: Anthropic.Messages.MessageParam;
  onSaveQuestion?: (content: string) => void;
  questionId: number;
}

export function MessageDisplay({
  message,
  onSaveQuestion,
  questionId,
}: MessageDisplayProps) {
  if (Array.isArray(message.content)) {
    return (
      <Box>
        {message.content.map((block, idx) => {
          if (block.type === "text") {
            return (
              <MarkdownDisplay
                key={idx}
                content={block.text}
                disableTextToSpeech={true}
              />
            );
          }

          if (
            block.type === "tool_use" &&
            block.name === "generateUpdatedQuestion"
          ) {
            return (
              <Box key={idx} className="mt-2">
                <UpdatedQuestionArtifactDisplay
                  question={block.input as TypeOf<typeof questionContentSchema>}
                  onSave={onSaveQuestion}
                />
              </Box>
            );
          }

          if (block.type === "tool_use" && block.name === "generateArtifact") {
            return (
              <Box key={idx} className="mt-2">
                <GeneratedArtifactDisplay
                  questionId={questionId}
                  artifact={
                    block.input as {
                      id: string;
                      title: string;
                      content: string;
                    }
                  }
                />
              </Box>
            );
          }

          return null;
        })}
      </Box>
    );
  }

  return (
    <MarkdownDisplay content={message.content} disableTextToSpeech={true} />
  );
}
