import { ActionIcon, Card, Select, Text, Tooltip } from "@mantine/core";
import { useFetcher } from "@remix-run/react";
import { CourseSelect } from "modules/courses/course.types";
import { Question, QuestionType } from "modules/questions/question.types";
import { useCallback, useEffect, useState } from "react";
import { MdAdd, MdRefresh } from "react-icons/md";
import { create } from "zustand";
import { action } from "~/routes/api.public.assignmentQuestions";
import { QuestionContent } from "~/routes/teacher.assignments.$assignmentId/QuestionContent";
import { showSuccessToast } from "../Notifications/customNotifications";
import { useAssignmentContext } from "./AssignmentContext";

// Define types
interface AssignmentWithQuestions {
  assignment: {
    id: number;
    title: string;
    description: string | null;
    state: string;
  };
  questions?: AssignmentQuestionType[];
}

// Simplified version of AssignmentQuestion from the system
interface AssignmentQuestionType {
  id: number;
  question: {
    id: number;
    questionContent: {
      questionText: string;
      type: QuestionType;
      explanation?: string | null;
      answerChoices?: Array<{
        id: string;
        answerText: string;
        isCorrect: boolean;
      }>;
      acceptableAnswers?: string[];
    };
    createdBy: string;
    state: string;
    knowledgeComponentId: number | null;
    aiGenerated: boolean;
    createdAt: Date;
    updatedAt: Date;
  };
}

interface AssignmentAssignmentsSearchState {
  courses: CourseSelect[];
  assignments: AssignmentWithQuestions[];
  fetchPublicCourses: () => Promise<void>;
  selectedCourseId: number | null;
  setSelectedCourseId: (id: number | null) => void;
  fetchAssignments: (courseId: number) => Promise<void>;
  selectedAssignmentId: number | null;
  setSelectedAssignmentId: (id: number | null) => void;
  questions: AssignmentQuestionType[];
  fetchQuestions: (assignmentId: number) => Promise<void>;
  addQuestionToAssignment: ({
    assignmentId,
    questionContent,
  }: {
    assignmentId: string;
    questionContent: string;
  }) => void;
}

export const useAssignmentAssignmentsSearchStore =
  create<AssignmentAssignmentsSearchState>((set) => ({
    courses: [],
    assignments: [],
    fetchPublicCourses: async () => {
      try {
        const response = await fetch("/api/public/courses");
        const data = await response.json();
        const courses = data.courses;
        if (courses.length > 0) {
          set({ courses: courses, selectedCourseId: courses[0].id });
        }
      } catch (error) {
        console.error("Failed to fetch public courses:", error);
      }
    },
    selectedCourseId: null,
    setSelectedCourseId: (id) => set({ selectedCourseId: id }),
    fetchAssignments: async (courseId: number) => {
      try {
        const response = await fetch(`/api/courses/${courseId}/assignments`);
        const data = await response.json();
        let update: {
          assignments: AssignmentWithQuestions[];
          selectedAssignmentId: number | null;
        } = {
          assignments: data.courseAssignments || [],
          selectedAssignmentId: null,
        };
        if (data.courseAssignments?.length > 0) {
          update = {
            ...update,
            selectedAssignmentId: data.courseAssignments[0].assignment.id,
          };
        }
        set(update);
      } catch (error) {
        console.error("Failed to fetch assignments:", error);
      }
    },
    selectedAssignmentId: null,
    setSelectedAssignmentId: (id) => set({ selectedAssignmentId: id }),
    questions: [],
    fetchQuestions: async (assignmentId: number) => {
      try {
        const response = await fetch(
          `/api/assignments/${assignmentId}/questions`,
        );
        const data = await response.json();
        set({ questions: data.questions || [] });
      } catch (error) {
        console.error("Failed to fetch questions:", error);
      }
    },
    addQuestionToAssignment: async ({ assignmentId, questionContent }) => {
      try {
        await fetch(`/api/public/assignmentQuestions`, {
          method: "POST",
          body: JSON.stringify({ assignmentId, questionContent }),
        });
      } catch (error) {
        console.error("Failed to add question to assignment:", error);
      }
    },
  }));

// Adapter component to handle type compatibility with QuestionContent
const QuestionContentAdapter = ({
  question,
}: {
  question: AssignmentQuestionType["question"];
}) => {
  return <QuestionContent question={question as unknown as Question} />;
};

// QuestionCard component to reduce duplication in rendering
interface QuestionCardProps {
  question: AssignmentQuestionType;
  isAdded: boolean;
  onSave: (content: string) => void;
}

const QuestionCard = ({ question, isAdded, onSave }: QuestionCardProps) => {
  const questionContent = JSON.stringify(question.question.questionContent);

  return (
    <Card key={question.id} className="mb-2 shadow-sm">
      <div className="flex justify-end">
        <Tooltip label={isAdded ? "Add another copy" : "Add to assignment"}>
          <ActionIcon
            onClick={() => onSave(questionContent)}
            className={`${
              isAdded
                ? "bg-secondary-600 text-white hover:bg-secondary-700"
                : "bg-secondary-50 hover:bg-secondary-100 text-secondary-600 hover:text-secondary-700"
            } transition-colors`}
            variant={isAdded ? "filled" : "subtle"}
          >
            {isAdded ? <MdRefresh size={20} /> : <MdAdd size={20} />}
          </ActionIcon>
        </Tooltip>
      </div>
      <QuestionContentAdapter question={question.question} />
    </Card>
  );
};

// SearchForm component
interface SearchFormProps {
  courses: CourseSelect[];
  assignments: AssignmentWithQuestions[];
  selectedCourseId: number | null;
  selectedAssignmentId: number | null;
  setSelectedCourseId: (id: number | null) => void;
  setSelectedAssignmentId: (id: number | null) => void;
}

const SearchForm = ({
  courses,
  assignments,
  selectedCourseId,
  selectedAssignmentId,
  setSelectedCourseId,
  setSelectedAssignmentId,
}: SearchFormProps) => {
  return (
    <form className="flex-shrink-0 p-4 pb-2">
      <Select
        className="mb-2"
        value={selectedCourseId?.toString()}
        onChange={(value) =>
          setSelectedCourseId(value ? parseInt(value) : null)
        }
        data={courses.map((course) => ({
          value: course.id.toString(),
          label: course.name,
        }))}
      />
      <Select
        className="mb-2"
        value={selectedAssignmentId?.toString()}
        onChange={(value) =>
          setSelectedAssignmentId(value ? parseInt(value) : null)
        }
        data={assignments.map((item) => ({
          value: item.assignment.id.toString(),
          label: item.assignment.title,
        }))}
      />
    </form>
  );
};

export default function AssignmentAssignmentsSearch() {
  // Get everything we need from the store in one go
  const {
    courses,
    fetchPublicCourses,
    selectedCourseId,
    setSelectedCourseId,
    fetchAssignments,
    selectedAssignmentId,
    setSelectedAssignmentId,
    assignments,
    questions,
    fetchQuestions,
  } = useAssignmentAssignmentsSearchStore();

  const { assignmentId } = useAssignmentContext();
  const fetcher = useFetcher<typeof action>();
  // Track added questions in the current session
  const [addedQuestions, setAddedQuestions] = useState<Record<string, boolean>>(
    {},
  );

  // Extract the submit question logic to avoid duplication
  const submitQuestion = useCallback(
    (content: string) => {
      fetcher.submit(
        JSON.stringify({ assignmentId, questionContent: content }),
        {
          method: "POST",
          action: "/api/public/assignmentQuestions",
          encType: "application/json",
        },
      );
    },
    [assignmentId, fetcher],
  );

  const onSave = useCallback(
    (content: string) => {
      const isAlreadyAdded = addedQuestions[content];

      if (isAlreadyAdded) {
        if (
          window.confirm(
            "This question has already been added to the assignment. Are you sure you want to add another copy?",
          )
        ) {
          submitQuestion(content);
        }
      } else {
        submitQuestion(content);

        // Mark this question as added
        setAddedQuestions((prev) => ({
          ...prev,
          [content]: true,
        }));
      }
    },
    [addedQuestions, submitQuestion, setAddedQuestions],
  );

  useEffect(() => {
    if (fetcher.data?.assignmentId) {
      showSuccessToast("Question added to assignment");
    }
  }, [fetcher.data]);

  // Fetch courses when the component mounts
  useEffect(() => {
    fetchPublicCourses();
  }, [fetchPublicCourses]);

  // when a course is selected, fetch the assignments
  useEffect(() => {
    if (selectedCourseId) {
      fetchAssignments(selectedCourseId);
    }
  }, [selectedCourseId, fetchAssignments]);

  // when an assignment is selected, fetch the questions
  useEffect(() => {
    if (selectedAssignmentId) {
      fetchQuestions(selectedAssignmentId);
    }
  }, [selectedAssignmentId, fetchQuestions]);

  if (!assignmentId) {
    return <div>No assignment ID found</div>;
  }

  return (
    <div className="flex flex-col h-full bg-beige-50">
      <SearchForm
        courses={courses}
        assignments={assignments}
        selectedCourseId={selectedCourseId}
        selectedAssignmentId={selectedAssignmentId}
        setSelectedCourseId={setSelectedCourseId}
        setSelectedAssignmentId={setSelectedAssignmentId}
      />
      <div className="flex-grow overflow-y-auto p-4">
        {questions.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <Text>No questions found</Text>
          </div>
        )}
        {questions.map((question) => {
          const content = JSON.stringify(question.question.questionContent);
          return (
            <QuestionCard
              key={question.id}
              question={question}
              isAdded={!!addedQuestions[content]}
              onSave={onSave}
            />
          );
        })}
      </div>
    </div>
  );
}
