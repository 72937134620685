import { useNavigate, useSearchParams } from "@remix-run/react";
import { FC } from "react";

interface SignInLinkProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const HomeAssignmentSignIn: FC<SignInLinkProps> = ({
  className,
  style,
  children,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const assignmentId = searchParams.get("assignmentId") || "";
  const chatId = searchParams.get("chatId") || "";

  const handleSignIn = () => {
    // First set the cookies
    fetch("/api/assignment-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        assignmentId,
        chatId,
        redirectTo: "/signin?type=homeAssignmentEditor",
      }),
    })
      .then(() => {
        // Then navigate to sign-in page
        navigate("/signin?type=homeAssignmentEditor");
      })
      .catch((error) => {
        console.error("Error setting assignment cookies:", error);
        // Navigate anyway as fallback
        navigate("/signin?type=homeAssignmentEditor");
      });
  };

  return (
    <button
      type="button"
      className={className}
      style={style}
      onClick={handleSignIn}
    >
      {children || "Sign In"}
    </button>
  );
};

export default HomeAssignmentSignIn;
