import { zodResolver } from "@hookform/resolvers/zod";
import {
  completedQuestionSchema,
  questionSchema,
} from "modules/questions/question.types";
import { cleanedDateInputSchema } from "modules/shared.types";
import { assignments, assignmentState } from "supabase/drizzle/schema";
import { z } from "zod";

// Basic types
export type AssignmentState = (typeof assignmentState.enumValues)[number];

// Zod schemas
export const assignmentSchema = z.object({
  title: z.coerce.string().min(1, "Title is required"),
  description: z.string().nullish(),
  launchDate: cleanedDateInputSchema.nullish(),
  dueDate: cleanedDateInputSchema.nullish(),
  groupsAssigned: z.array(z.number()).nullish().default([]),
  createdBy: z.string().nullish(),
});

export const assignmentQuestionSchema = z.object({
  id: z.number(),
  assignmentId: z.number(),
  order: z.number(),
  questionId: z.number(),
  question: questionSchema,
  aiGenerated: z.boolean().default(false),
});

export const AssignmentWithQuestionsSchema = assignmentSchema.extend({
  id: z.number(),
  state: z.enum(assignmentState.enumValues),
  assignmentQuestions: z.array(assignmentQuestionSchema),
});

export const completeAssignmentSchema = assignmentSchema.extend({
  id: z.number(),
  title: z.coerce.string().min(1, "Title is required"),
  groupsAssigned: z
    .array(z.number())
    .min(1, "Assignment must be assigned to at least one class"),
  dueDate: z
    .date({
      required_error: "Assignment must have due date",
      invalid_type_error: "Assignment must have due date",
    })
    .min(new Date(), "Due date must be in the future"),
  assignmentQuestions: z
    .array(
      z.object({
        id: z.number(),
        assignmentId: z.number(),
        order: z.number(),
        questionId: z.number(),
        question: completedQuestionSchema,
      }),
    )
    .min(1, "At least one question is required"),
});

// Inferred types from Zod schemas
export type AssignmentFormData = z.infer<typeof assignmentSchema>;
export type AssignmentWithQuestions = z.infer<
  typeof AssignmentWithQuestionsSchema
>;
export type CompleteAssignment = z.infer<typeof completeAssignmentSchema>;

// Types inferred from Drizzle schema
export type SelectAssignment = typeof assignments.$inferSelect;
export type InsertAssignment = typeof assignments.$inferInsert;

// Custom types
export type AssignmentWithAssignedAssignments = Omit<
  SelectAssignment,
  "createdAt" | "createdBy" | "updatedAt"
> & {
  dueDate: Date | null | undefined;
  launchDate: Date | null | undefined;
  groupsAssigned: number[];
};

export type AssignmentIndex = Pick<
  SelectAssignment,
  "id" | "title" | "state"
> & {
  dueDate: Date | null | undefined;
  assignedGroupNames: string[];
};

export type AssignmentWithDueDate = Pick<SelectAssignment, "id" | "title"> & {
  dueDate: Date | null | undefined;
};

export type StudentAssignmentIndex = Pick<SelectAssignment, "id" | "title"> & {
  dueDate: Date | null | undefined;
  totalQuestions: number;
  completedQuestions: number;
  timedOutQuestions?: number;
  numFirstAttemptCorrect: number;
  status?: string;
  score?: number;
  progress?: number;
};

// Utility functions
export const assignmentFormResolver = zodResolver(assignmentSchema);
