import { Anchor, Button, Group, Menu, Modal, Text } from "@mantine/core";
import { Link, useOutletContext } from "@remix-run/react";
import { AssignmentWithQuestions } from "modules/assignments/assignment.types";
import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { TbFileText, TbForms } from "react-icons/tb";
import {
  showErrorToast,
  showSuccessToast,
} from "~/components/Notifications/customNotifications";
import { ExportType } from "~/modules/googleExports/types";
import { OutletContext } from "~/root";

interface ExportDropdownProps {
  assignment: AssignmentWithQuestions;
}

// Define the response structure from our API
interface ExportResponse {
  success: boolean;
  url?: string;
  error?: string;
}

export default function ExportDropdown({ assignment }: ExportDropdownProps) {
  const { user } = useOutletContext<OutletContext>();
  const [isExporting, setIsExporting] = useState(false);
  const [lastExportType, setLastExportType] = useState<ExportType | null>(null);
  const [exportResponse, setExportResponse] = useState<ExportResponse | null>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportUrl, setExportUrl] = useState<string>("");
  const [exportTypeName, setExportTypeName] = useState<string>("");

  const handleExport = async (type: ExportType) => {
    if (!user) {
      showErrorToast(
        "Please sign up to use export functionality.",
        "bottom-left",
      );
      return;
    }

    if (
      !assignment.assignmentQuestions ||
      assignment.assignmentQuestions.length === 0
    ) {
      showErrorToast(
        "This assignment has no questions to export.",
        "bottom-left",
      );
      return;
    }

    setIsExporting(true);
    setLastExportType(type);

    try {
      const response = await fetch("/api/assignments/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: assignment.title || "Unnamed Assignment",
          questions: assignment.assignmentQuestions,
          type,
        }),
      });

      const data: ExportResponse = await response.json();
      setExportResponse(data);
    } catch (error) {
      console.error("Error exporting assignment:", error);
      setExportResponse({
        success: false,
        error: "Network error occurred. Please try again.",
      });
    }
  };

  // Use useEffect to handle the completed export
  useEffect(() => {
    if (!exportResponse) return;

    setIsExporting(false);

    if (exportResponse.success && exportResponse.url) {
      // Instead of opening the window directly, set state to show the modal
      const exportType =
        lastExportType === "form" ? "Google Form" : "Google Document";

      setExportUrl(exportResponse.url);
      setExportTypeName(exportType);
      setIsModalOpen(true);

      showSuccessToast(`Successfully exported to ${exportType}`, "bottom-left");

      // Show copy instructions for form exports
      if (lastExportType === "form") {
        showSuccessToast(
          "To make a copy of this form, click 'Use Template' button at the top of the form.",
          "bottom-left",
        );
      }
    } else {
      showErrorToast(
        exportResponse.error || "Failed to export. Please try again.",
        "bottom-left",
      );
    }

    // Reset the response after handling it
    setExportResponse(null);
  }, [exportResponse, lastExportType]);

  return (
    <>
      <Menu position="bottom-end" shadow="md">
        <Menu.Target>
          <Button
            leftSection={<FcGoogle size={18} />}
            variant="filled"
            className="text-secondary-600 bg-gray-50 hover:bg-gray-100"
            loaderProps={{
              color: "#1C7ED6",
            }}
            loading={isExporting}
          >
            <span className="block sm:hidden">Export</span>
            <span className="hidden sm:block">Export to Google</span>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Export Format</Menu.Label>
          <Menu.Item
            leftSection={<TbFileText size={16} />}
            onClick={() => handleExport("doc")}
            disabled={!user}
          >
            <Text size="sm">Google Document</Text>
          </Menu.Item>
          <Menu.Item
            leftSection={<TbForms size={16} />}
            onClick={() => handleExport("form")}
            disabled={!user}
          >
            <Text size="sm">Google Form</Text>
          </Menu.Item>

          {!user && (
            <Menu.Label className="text-sm text-center text-text-primary">
              <Anchor component={Link} to="/signin">
                Sign up
              </Anchor>{" "}
              to get all export functionalities (completely free)
            </Menu.Label>
          )}
        </Menu.Dropdown>
      </Menu>

      {/* Modal for showing export link */}
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Open ${exportTypeName}`}
        size="md"
        centered
      >
        <Text mb="md">
          Your export is ready! Click the button below to open your{" "}
          {exportTypeName}:
        </Text>
        <Button
          component="a"
          href={exportUrl}
          target="_blank"
          rel="noopener noreferrer"
          size="md"
          fullWidth
          leftSection={
            lastExportType === "form" ? (
              <TbForms size={18} />
            ) : (
              <TbFileText size={18} />
            )
          }
        >
          Open {exportTypeName}
        </Button>
        {lastExportType === "form" && (
          <Text mt="md" size="sm" color="dimmed">
            Remember to click the &apos;Use Template&apos; button at the top of
            the form to make a copy for your own use.
          </Text>
        )}
        <Group justify="flex-end" mt="lg">
          <Button onClick={() => setIsModalOpen(false)} color="gray">
            Close
          </Button>
        </Group>
      </Modal>
    </>
  );
}
