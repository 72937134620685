import { Button, Menu } from "@mantine/core";
import { useFetcher } from "@remix-run/react";
import {
  AssignmentQuestion,
  QuestionType,
} from "modules/questions/question.types";
import {
  TbChevronDown,
  TbCircleDot,
  TbSquareCheck,
  TbWriting,
} from "react-icons/tb";
import {
  questionTypeDisplayText,
  QuestionTypeIcon,
} from "~/utils/questionFormatting/questionType";

interface QuestionTypeDropdownProps {
  question: AssignmentQuestion["question"];
}

export default function QuestionTypeDropdown({
  question,
}: QuestionTypeDropdownProps) {
  const fetcher = useFetcher();

  const handleQuestionTypeChange = (newType: QuestionType) => {
    fetcher.submit(
      { type: newType },
      {
        method: "POST",
        action: `/api/questions/${question.id}/updateType`,
        encType: "application/json",
      },
    );
  };

  return (
    <Menu>
      <Menu.Target>
        <Button
          className="p-2 rounded-lg"
          variant="default"
          leftSection={
            <QuestionTypeIcon
              color="green"
              type={question.questionContent.type}
            />
          }
          rightSection={<TbChevronDown />}
        >
          {questionTypeDisplayText(question.questionContent.type)}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<TbWriting color="green" />}
          onClick={() => handleQuestionTypeChange(QuestionType.SHORT_ANSWER)}
        >
          Short Answer
        </Menu.Item>
        <Menu.Item
          leftSection={<TbCircleDot color="green" />}
          onClick={() => handleQuestionTypeChange(QuestionType.MULTIPLE_CHOICE)}
        >
          Multiple Choice
        </Menu.Item>
        <Menu.Item
          leftSection={<TbSquareCheck color="green" />}
          onClick={() => handleQuestionTypeChange(QuestionType.SELECT_ALL)}
        >
          Select All
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
