import {
  Anchor,
  Box,
  Checkbox,
  Input,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DateTimePicker, DateValue } from "@mantine/dates";
import { Link } from "@remix-run/react";
import {
  AssignmentFormData,
  assignmentFormResolver,
} from "modules/assignments/assignment.types";
import { SelectGroup } from "modules/groups/group.types";
import { useEffect, useRef } from "react";
import { useRemixForm } from "remix-hook-form";
import { useDebounceFetcher } from "remix-utils/use-debounce-fetcher";
import { useAssignmentFormStore } from "~/stores/assignmentForm.store";

interface AssignmentFormProps {
  initialData?: AssignmentFormData;
  groups: Pick<SelectGroup, "id" | "groupName">[];
  action?: string;
}

export default function AssignmentForm({
  initialData,
  groups,
  action,
}: AssignmentFormProps) {
  const formRef = useRef<HTMLFormElement>(null);

  // Get the fetcher for form submission
  const fetcher = useDebounceFetcher();

  // Get state and actions from Zustand store
  const {
    selectedGroups,
    minDate,
    isNew,
    setInitialData,
    setAction,
    setMinDate,
    handleTextChange,
    handleCheckboxChange,
    handleDateChange,
  } = useAssignmentFormStore();

  // Setup form validation
  const { register } = useRemixForm<AssignmentFormData>({
    mode: "onSubmit",
    resolver: assignmentFormResolver,
    defaultValues: initialData,
  });

  // Initialize store values when props change
  useEffect(() => {
    setInitialData(initialData);
    setAction(action);
  }, [initialData, action, setInitialData, setAction]);

  // Set minimum date on mount
  useEffect(() => {
    setMinDate(new Date());
  }, [setMinDate]);

  // Form field change handler wrappers
  const onTextFieldChange = () => {
    if (formRef.current) {
      handleTextChange(formRef.current, fetcher);
    }
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formRef.current) {
      const groupId = Number(e.target.value);
      handleCheckboxChange(groupId, e.target.checked, formRef.current, fetcher);
    }
  };

  const onDateChange = (value: DateValue) => {
    if (formRef.current) {
      handleDateChange(value, formRef.current, fetcher);
    }
  };

  return (
    <fetcher.Form method="post" action={action} ref={formRef}>
      <Box>
        <TextInput
          {...register("title")}
          onChange={onTextFieldChange}
          label="Assignment name"
          placeholder="Assignment name (ex. Week 3 Review)"
          className="pb-4"
          required
        />
        <Textarea
          {...register("description")}
          disabled={isNew}
          onChange={onTextFieldChange}
          label="Description or instructions (optional)"
          placeholder="Description or instructions (ex. Please refer to chapter 2 of this week's reading)"
          className="pb-4"
        />
        <Input.Wrapper label="Assign to" className="pb-4" required>
          <Stack>
            {groups.length === 0 ? (
              <Box p="md">
                <Text size="sm">
                  You don&apos;t have any classes yet.{" "}
                  <Anchor
                    component={Link}
                    to="/teacher/rosters"
                    className="underline"
                  >
                    Go here
                  </Anchor>{" "}
                  to create your first class.
                </Text>
              </Box>
            ) : (
              groups.map((group) => (
                <Checkbox
                  key={group.id}
                  disabled={isNew}
                  {...register("groupsAssigned")}
                  value={group.id}
                  defaultValue={undefined}
                  checked={selectedGroups.includes(Number(group.id))}
                  onChange={onCheckboxChange}
                  color="primary"
                  size="md"
                  label={group.groupName}
                />
              ))
            )}
          </Stack>
        </Input.Wrapper>
        <DateTimePicker
          {...register("dueDate", { valueAsDate: true })}
          defaultValue={initialData?.dueDate}
          minDate={minDate}
          withSeconds={false}
          onChange={onDateChange}
          disabled={isNew}
          label="Due date"
          placeholder="Date"
          valueFormat="MM/DD/YYYY hh:mm A"
          required
        />
      </Box>
    </fetcher.Form>
  );
}
