import { Button } from "@mantine/core";
import { useFetcher } from "@remix-run/react";
import { TbPlus } from "react-icons/tb";
import { action } from "../api.assignments.questions";

interface AddQuestionButtonProps {
  assignmentId: string;
  onAIClick: () => void;
}

export default function AddQuestionButton({
  assignmentId,
}: AddQuestionButtonProps) {
  const emptyQuestionFetcher = useFetcher<typeof action>();

  const handleCreateEmptyQuestion = () => {
    emptyQuestionFetcher.submit(JSON.stringify({ assignmentId }), {
      method: "POST",
      action: `/api/assignments/questions`,
      encType: "application/json",
    });
  };

  const isLoading = emptyQuestionFetcher.state !== "idle";

  return (
    <Button
      className="p-2 rounded-lg"
      type="submit"
      loading={isLoading}
      color="primary"
      leftSection={<TbPlus />}
      size="sm"
      onClick={handleCreateEmptyQuestion}
    >
      Manually add question
    </Button>
  );
}
