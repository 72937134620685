import { ActionIcon, Box, Button, Flex, Select } from "@mantine/core";
import { MdAdd, MdClose } from "react-icons/md";
import { colors } from "~/styles/colors";
import { PanelContentType } from "./PanelContent";

type PanelHeaderProps = {
  activePanelContent: PanelContentType;
  setActivePanelContent: (value: PanelContentType) => void;
  onResetChat: () => void;
  onClose: () => void;
  isMobile?: boolean;
};

export default function PanelHeader({
  activePanelContent,
  setActivePanelContent,
  onResetChat,
  onClose,
  isMobile,
}: PanelHeaderProps) {
  return (
    <Box className="border-b border-gray-200 p-2 h-10 flex items-center justify-between bg-amber-50">
      <Box className="pl-2">
        <Select
          radius="xl"
          value={activePanelContent}
          onChange={(value) => setActivePanelContent(value as PanelContentType)}
          data={[
            { value: "ai-assistant", label: "AI Assistant" },
            { value: "question-search", label: "Foundational Questions" },
            { value: "assignment-search", label: "Exam-Like Questions" },
          ]}
          size="xs"
          styles={{
            root: { minWidth: "140px" },
            input: {
              border: `1px solid ${colors.secondary[500]}`,
              backgroundColor: colors.primary[25],
            },
          }}
          allowDeselect={false}
        />
      </Box>
      <Flex gap="xs">
        {activePanelContent === "ai-assistant" && (
          <Button
            size="xs"
            variant="light"
            color="secondary"
            onClick={onResetChat}
            title="Start a new chat"
            leftSection={<MdAdd size={16} />}
          >
            New Chat
          </Button>
        )}
        {!isMobile && (
          <ActionIcon variant="subtle" color="gray" onClick={onClose}>
            <MdClose size={16} />
          </ActionIcon>
        )}
      </Flex>
    </Box>
  );
}
