import {
  SandpackCodeEditor,
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
  SandpackProviderProps,
} from "@codesandbox/sandpack-react";
import { Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { sandpackConfig } from "./config";
import { sandpackHooks } from "./hooks";

const defaultFiles = {
  ...sandpackConfig,
  ...sandpackHooks,
};

interface CustomSandboxProps extends SandpackProviderProps {
  showLoadingScreen?: boolean;
}

const CustomSandbox = (props: CustomSandboxProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [activeTab, setActiveTab] = useState<"preview" | "code">("preview");

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const sandpackOptions: SandpackProviderProps = {
    ...props,
    files: {
      ...defaultFiles,
      ...props.files,
    },
    options: {
      ...props.options,
      visibleFiles: ["/App.tsx"],
      externalResources: [
        ...(props.options?.externalResources || []),
        "https://cdn.tailwindcss.com",
      ],
    },
    customSetup: {
      dependencies: {
        ...props.customSetup?.dependencies,
        "tailwindcss-animate": "latest",
        "class-variance-authority": "latest",
        "lucide-react": "latest",
        clsx: "latest",
        "tailwind-merge": "latest",
      },
    },
  };

  return (
    <SandpackProvider template="react-ts" theme="auto" {...sandpackOptions}>
      <div className="flex flex-col h-full">
        <div className="flex border-b">
          <button
            className={`px-4 py-2 ${
              activeTab === "preview" ? "bg-gray-100" : "bg-white"
            }`}
            onClick={() => setActiveTab("preview")}
          >
            Preview
          </button>
          <button
            className={`px-4 py-2 ${
              activeTab === "code" ? "bg-gray-100" : "bg-white"
            }`}
            onClick={() => setActiveTab("code")}
          >
            Code
          </button>
        </div>
        <div className="flex-grow">
          <SandpackLayout className="h-full">
            <div className="h-full w-full">
              <div
                className={activeTab === "preview" ? "block h-full" : "hidden"}
              >
                <div className="relative h-[80vh]">
                  <SandpackPreview
                    className="h-full"
                    showOpenInCodeSandbox={false}
                  />
                  {props.showLoadingScreen && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white">
                      <Loader size="xl" variant="dots" />
                    </div>
                  )}
                </div>
              </div>
              <div className={activeTab === "code" ? "block h-full" : "hidden"}>
                <SandpackCodeEditor className="h-[80vh]" readOnly />
              </div>
            </div>
          </SandpackLayout>
        </div>
      </div>
    </SandpackProvider>
  );
};

export default CustomSandbox;
