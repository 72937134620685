import { ActionIcon, Button, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useFetcher } from "@remix-run/react";
import { resourceContentSchema } from "modules/resources/resource.types";
import { MdCheck } from "react-icons/md";
import CustomSandbox from "~/components/Sandpack/CustomSandbox";
import { action } from "../api.questions.$questionId.resources";

interface GeneratedArtifactDisplayProps {
  artifact: {
    id: string;
    title: string;
    content: string;
  };
  questionId: number;
}

export function GeneratedArtifactDisplay({
  artifact,
  questionId,
}: GeneratedArtifactDisplayProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const fetcher = useFetcher<typeof action>();

  const handleSave = () => {
    // ensure that the content adheres to the resourceContentSchema:
    const contentParseResult = resourceContentSchema.safeParse({
      type: "react-component",
      content: artifact.content,
    });
    if (!contentParseResult.success) {
      throw new Error(
        `Invalid resource content: ${contentParseResult.error.message}`,
      );
    }
    fetcher.submit(
      {
        title: artifact.title,
        content: contentParseResult.data,
        type: "react-component",
      },
      {
        method: "POST",
        action: `/api/questions/${questionId}/resources`,
        encType: "application/json",
      },
    );
  };

  return (
    <div className="relative">
      <div className="absolute top-2 right-2">
        <Tooltip label="Save as resource">
          <ActionIcon
            onClick={handleSave}
            className="bg-green-50 hover:bg-green-100 text-green-600 hover:text-green-700 transition-colors border-primary-500"
            size="lg"
            variant="outline"
            loading={fetcher.state === "submitting"}
          >
            <MdCheck size={24} />
          </ActionIcon>
        </Tooltip>
      </div>

      <Button onClick={open} variant="light" size="sm">
        View Interactive Component
      </Button>

      <Modal opened={opened} onClose={close} size="xl" title={artifact.title}>
        <CustomSandbox
          files={{
            "/App.tsx": {
              code: artifact.content,
            },
          }}
        />
      </Modal>
    </div>
  );
}
