import { z } from "zod";

export const cleanedDateInputSchema = z
  .union([z.string(), z.date(), z.null()])
  .refine(
    (val) => {
      if (typeof val === "string") {
        return val === "" || !isNaN(Date.parse(val));
      }
      return true;
    },
    {
      message: "Invalid date format",
    },
  )
  .transform((val) => {
    if (val === "" || val === null) {
      return null;
    }
    return new Date(val);
  });
