import { Text, Title } from "@mantine/core";
import { AssignmentQuestion } from "modules/questions/question.types";
import AddQuestionButton from "./AddQuestionButton";
import QuestionEditor from "./QuestionEditor";

interface QuestionsViewProps {
  questions: AssignmentQuestion[];
  assignmentId: string;
  canAddQuestions: boolean;
  onAIClick: () => void;
}

export default function QuestionsView({
  questions,
  assignmentId,
  canAddQuestions,
  onAIClick,
}: QuestionsViewProps) {
  return (
    <div>
      {questions.map((item, index) => (
        <QuestionEditor key={item.question.id} item={item} index={index} />
      ))}
      <div className="flex justify-center flex-col items-center gap-5">
        {questions.length === 0 && (
          <>
            <div className="text-center">
              <Title order={2} className="font-semibold mb-2">
                Assignment Builder
              </Title>
              <Text size="sm">
                No questions yet. Questions you add (both manually or with the
                AI assistant) will appear here.
              </Text>
            </div>
          </>
        )}
        {canAddQuestions && (
          <AddQuestionButton
            assignmentId={assignmentId}
            onAIClick={onAIClick}
          />
        )}
      </div>
    </div>
  );
}
