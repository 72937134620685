import type { Attachment } from "@ai-sdk/ui-utils";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Paper,
  Tabs,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "@remix-run/react";
import { AssignmentWithQuestions } from "modules/assignments/assignment.types";
import { GroupDropdownItem } from "modules/groups/group.types";
import { useEffect, useRef, useState } from "react";
import {
  MdArrowBack,
  MdEditDocument,
  MdPersonAdd,
  MdSave,
} from "react-icons/md";
import { TbRobot } from "react-icons/tb";
import type { ImperativePanelHandle } from "react-resizable-panels";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { OutletContext } from "~/root";
import DraggableQuestionsView from "~/routes/teacher.assignments.$assignmentId_.edit/DraggableQuestionsView";
import ExportDropdown from "~/routes/teacher.assignments.$assignmentId_.edit/ExportDropdown";
import HomeAssignmentSignIn from "../Button/HomeAssignmentSignIn";
import { AssignmentContextProvider } from "./AssignmentContext";
import PanelContent, { PanelContentType } from "./PanelContent";
import PanelHeader from "./PanelHeader";
import PublishSettings from "./PublishSettings";

type AssignmentEditorProps = {
  assignment: AssignmentWithQuestions | null;
  onClose?: () => void;
  groups?: GroupDropdownItem[];
  initialInput?: string;
  initialAttachments?: Attachment[];
};

export default function AssignmentEditor({
  assignment,
  onClose,
  groups = [],
  initialInput,
  initialAttachments = [],
}: AssignmentEditorProps) {
  const { user, teacherProfile } = useOutletContext<OutletContext>();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(true);
  const rightPanelRef = useRef<ImperativePanelHandle>(null);
  const leftPanelRef = useRef<ImperativePanelHandle>(null);
  const resetChatRef = useRef<(() => void) | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>("editor");
  const [searchParams] = useSearchParams();

  const [activePanelContent, setActivePanelContent] =
    useState<PanelContentType>("ai-assistant");

  const navigate = useNavigate();
  const location = useLocation();

  // Handle initial panel state for mobile
  useEffect(() => {
    if (isMobile) {
      // Initialize to assistant view by default
      setActiveTab("ai");
      leftPanelRef.current?.collapse();
      rightPanelRef.current?.expand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, leftPanelRef.current, rightPanelRef.current]);

  const handleRightClose = () => {
    rightPanelRef.current?.collapse();
    setIsRightPanelOpen(false);
  };

  const handleRightOpen = () => {
    if (isMobile) {
      leftPanelRef.current?.collapse();
      rightPanelRef.current?.expand();
      setActiveTab("ai");
    } else {
      rightPanelRef.current?.expand();
      setIsRightPanelOpen(true);
    }
  };

  const handleTabChange = (value: string | null) => {
    if (!value) return;

    setActiveTab(value);

    if (isMobile) {
      if (value === "editor") {
        leftPanelRef.current?.expand();
        rightPanelRef.current?.collapse();
      } else {
        rightPanelRef.current?.expand();
        leftPanelRef.current?.collapse();
      }
    }
  };

  // Handle reset chat button click
  const handleResetChat = async () => {
    try {
      // Create a new chat using fetch
      const response = await fetch("/api/chat/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to create new chat");
      }

      const newChat = await response.json();

      // Update the URL with the new chatId
      const url = new URL(window.location.href);
      url.searchParams.set("chatId", newChat.id.toString());

      // Navigate to the same page with the new chatId
      navigate(`${location.pathname}?${url.searchParams.toString()}`, {
        replace: false,
      });
    } catch (error) {
      console.error("Error creating new chat:", error);
    }
  };

  const truncateTitle = (text: string, maxLength = isMobile ? 12 : 40) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  // Stringify the assignment object for the AI assistant
  const assignmentContext = {
    assignment: JSON.stringify(assignment),
  };

  // Safe onClose handler that won't be undefined
  const handlePanelHeaderClose = () => {
    if (!isMobile) {
      handleRightClose();
    }
  };

  if (!assignment) {
    return null;
  }
  const primaryActionButton = () => {
    if (user && teacherProfile) {
      return <PublishSettings assignment={assignment} groups={groups} />;
    }
    if (user && !teacherProfile) {
      // Get current assignmentId and chatId from URL or context
      const assignmentId = assignment.id.toString();
      const chatId = searchParams.get("chatId") || "";

      return (
        <Button
          component={Link}
          to={`/teacher/pre-pilot?assignmentId=${assignmentId}&chatId=${chatId}`}
          leftSection={<MdSave size={20} />}
        >
          Save Assignment
        </Button>
      );
    }
    return (
      <Button
        component={HomeAssignmentSignIn}
        className="variant-filled color-primary size-sm"
        leftSection={<MdPersonAdd size={20} />}
      >
        Sign Up
      </Button>
    );
  };
  return (
    <AssignmentContextProvider assignmentId={assignment.id.toString()}>
      <Box className="w-full overflow-hidden h-screen flex flex-col">
        {/* Main header - for the entire editor */}
        <Flex className="border-b border-gray-200 p-3 h-14 items-center justify-between bg-secondary-600 text-white">
          <Flex gap="md" align="center">
            <ActionIcon
              variant="subtle"
              color="white"
              onClick={onClose}
              title="Go back"
            >
              <MdArrowBack size={20} />
            </ActionIcon>

            <Text className="font-medium text-lg">
              {truncateTitle(assignment.title || "Untitled Assignment")}
            </Text>
          </Flex>
          <Flex gap="sm" align="center">
            <ExportDropdown assignment={assignment} />
            {primaryActionButton()}
          </Flex>
        </Flex>

        {/* Mobile Tabs */}
        {isMobile && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className="bg-slate-50"
            radius="none"
          >
            <Tabs.List>
              <Tabs.Tab
                value="editor"
                className="flex-1 text-center"
                leftSection={<MdEditDocument size={18} />}
              >
                Assignment
              </Tabs.Tab>
              <Tabs.Tab
                value="ai"
                className="flex-1 text-center"
                leftSection={<TbRobot size={18} />}
              >
                Assistant
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        )}

        {/* Panels container - takes remaining height */}
        <PanelGroup direction="horizontal" className="flex-1">
          {/* Left Panel - Assignment Editor Area (main area) */}
          <Panel
            ref={leftPanelRef}
            defaultSize={!isRightPanelOpen ? 100 : 60}
            minSize={0}
            collapsible={isMobile}
          >
            <div className="h-full p-4 relative overflow-auto bg-neutral-50">
              {/* Button to open AI panel when it's closed */}
              {!isRightPanelOpen && !isMobile && (
                <div className="fixed top-16 right-3 z-10">
                  <ActionIcon
                    variant="filled"
                    color="secondary"
                    onClick={handleRightOpen}
                    title="Open AI Assistant"
                    size="lg"
                    className="shadow-md hover:shadow-lg transition-shadow rounded-full p-2"
                  >
                    <TbRobot size={24} />
                  </ActionIcon>
                </div>
              )}

              <div className="max-w-5xl mx-auto pt-12 px-4">
                <DraggableQuestionsView
                  questions={assignment?.assignmentQuestions || []}
                  assignmentId={assignment?.id.toString() || ""}
                  canAddQuestions={true}
                  onAIClick={handleRightOpen}
                />
              </div>
            </div>
          </Panel>

          {/* Right Panel - AI Assistant or Question Search */}
          <PanelResizeHandle className="w-0.5 bg-gray-200 hover:bg-gray-300 transition-colors" />
          <Panel
            ref={rightPanelRef}
            defaultSize={40}
            minSize={0}
            maxSize={isMobile ? 100 : 60}
            collapsible
            onCollapse={() => setIsRightPanelOpen(false)}
            onExpand={() => setIsRightPanelOpen(true)}
          >
            <Paper className="h-full flex flex-col" radius={0} p={0}>
              {/* Toolbar with content selector, reset and close buttons */}
              <PanelHeader
                isMobile={isMobile}
                activePanelContent={activePanelContent}
                setActivePanelContent={setActivePanelContent}
                onResetChat={handleResetChat}
                onClose={handlePanelHeaderClose}
              />

              {/* Panel content */}
              <Box className="flex-1 overflow-hidden">
                <PanelContent
                  type={activePanelContent}
                  assignmentContext={assignmentContext}
                  resetChatRef={resetChatRef}
                  initialInput={initialInput}
                  initialAttachments={initialAttachments}
                />
              </Box>
            </Paper>
          </Panel>
        </PanelGroup>
      </Box>
    </AssignmentContextProvider>
  );
}
