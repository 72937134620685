import { Modal } from "@mantine/core";
import { AssignmentQuestion } from "modules/questions/question.types";
import { useEffect, useState } from "react";
import { QuestionContentEditor } from "~/components/QuestionContentEditor/QuestionContentEditor";
import QuestionEditorAIAssistant from "./QuestionEditorAIAssistant";

interface QuestionEditorAssistantProps {
  opened: boolean;
  onClose: () => void;
  question: AssignmentQuestion["question"];
  onContentChange: (
    value: string,
    field: "questionText" | "explanation",
  ) => void;
}

export default function QuestionEditorAssistant({
  opened,
  onClose,
  question,
  onContentChange,
}: QuestionEditorAssistantProps) {
  const [waitingForUpdatedContent, setWaitingForUpdatedContent] =
    useState(false);
  const [numAIUpdates, setNumAIUpdates] = useState(0);

  useEffect(() => {
    // if both waiting for ai update and question content is updated, then notify that QuestionContentEditor needs to re-render:
    if (waitingForUpdatedContent) {
      setNumAIUpdates(numAIUpdates + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  return (
    <Modal.Root opened={opened} onClose={onClose} fullScreen>
      <Modal.Overlay />
      <Modal.Content className="h-screen w-screen flex flex-col">
        <Modal.Header className="bg-primary-200">
          <Modal.Title>Question Editor</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body className="flex-1 min-h-0 p-0">
          <div className="flex h-full w-full">
            {/* Left Pane */}
            <div className="flex-1 border-r border-gray-200 p-8 overflow-auto">
              <QuestionContentEditor
                key={numAIUpdates}
                question={question}
                onContentChange={onContentChange}
              />
            </div>

            {/* Right Pane */}
            <div className="flex-1 overflow-auto">
              <QuestionEditorAIAssistant
                question={question}
                setWaitingForUpdatedContent={setWaitingForUpdatedContent}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
