import { Anthropic } from "@anthropic-ai/sdk";
import { Box, Loader, Text } from "@mantine/core";
import { useFetcher } from "@remix-run/react";
import { parse } from "best-effort-json-parser";
import {
  Answer,
  AssignmentQuestion,
  updateContentSchema,
} from "modules/questions/question.types";
import { useCallback, useRef, useState } from "react";
import { isUuid, uuidv4 } from "~/utils/ids/uuid";
import { streamQuestionEditResponse } from "~/utils/stream/questionEditStream.util";
import { action } from "../api.questions.$questionId.updateContent";
import { MessageDisplay } from "./MessageDisplay";
import { MessageInput } from "./MessageInput";

interface QuestionEditorAIAssistantProps {
  question: AssignmentQuestion["question"];
  setWaitingForUpdatedContent: (value: boolean) => void;
}

export default function QuestionEditorAIAssistant({
  question,
  setWaitingForUpdatedContent,
}: QuestionEditorAIAssistantProps) {
  const [messages, setMessages] = useState<Anthropic.Messages.MessageParam[]>(
    [],
  );
  const updateQuestionFetcher = useFetcher<typeof action>();
  const [editorKey, setEditorKey] = useState(0);
  const [currentInput, setCurrentInput] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const streamingMessageId = useRef<string>("");
  const jsonBuffer = useRef<string>("");
  const toolUseName = useRef<string>("");
  const [isToolExecuting, setIsToolExecuting] = useState(false);

  const handleUpdatedQuestionSaved = useCallback(
    async (content: string) => {
      try {
        const parsedContent = JSON.parse(content);

        // Fix invalid UUIDs in answer choices if present
        if (parsedContent.answerChoices) {
          parsedContent.answerChoices = parsedContent.answerChoices.map(
            (choice: Partial<Answer>) => ({
              ...choice,
              id: choice.id && isUuid(choice.id) ? choice.id : uuidv4(),
            }),
          );
        }

        const contentParseResult = updateContentSchema.safeParse(parsedContent);
        if (!contentParseResult.success) {
          throw new Error(
            `Invalid content: ${contentParseResult.error.message}`,
          );
        }

        await updateQuestionFetcher.submit(JSON.stringify(parsedContent), {
          method: "POST",
          action: `/api/questions/${question.id}/updateContent`,
          encType: "application/json",
        });
        setWaitingForUpdatedContent(true);
      } catch (error) {
        console.error("Failed to process question content:", error);
        throw error;
      }
    },
    [updateQuestionFetcher, question.id, setWaitingForUpdatedContent],
  );

  const handleStream = useCallback(
    async (messageHistory: Anthropic.Messages.MessageParam[]) => {
      setIsStreaming(true);
      try {
        await streamQuestionEditResponse({
          messages: messageHistory,
          question,
          onEvent: (event) => {
            if (event.type === "content_block_delta") {
              const delta = event.delta;

              if (delta.type === "text_delta") {
                setMessages((prev) =>
                  prev.map((msg, index) =>
                    index === prev.length - 1
                      ? { ...msg, content: msg.content + delta.text }
                      : msg,
                  ),
                );
              } else if (delta.type === "input_json_delta") {
                jsonBuffer.current += delta.partial_json;
              }
            } else if (
              event.type === "message_start" &&
              event.message.role === "assistant"
            ) {
              setMessages((prev) => [
                ...prev,
                {
                  role: event.message.role,
                  content: event.message.content,
                },
              ]);
              streamingMessageId.current = event.message.id;
            } else if (
              event.type === "content_block_start" &&
              event.content_block.type === "tool_use"
            ) {
              toolUseName.current = event.content_block.name;
              setIsToolExecuting(true);
            } else if (event.type === "content_block_stop") {
              setIsToolExecuting(false);
              if (jsonBuffer.current) {
                try {
                  const parsedJson = parse(jsonBuffer.current);
                  let toolUseMessage:
                    | Anthropic.Messages.MessageParam
                    | undefined;
                  let toolResultMessage:
                    | Anthropic.Messages.MessageParam
                    | undefined;

                  switch (toolUseName.current) {
                    case "generateUpdatedQuestion": {
                      if (parsedJson?.question) {
                        toolUseMessage = {
                          role: "assistant",
                          content: [
                            {
                              type: "tool_use",
                              id: streamingMessageId.current,
                              name: toolUseName.current,
                              input: parsedJson.question,
                            },
                          ],
                        };

                        toolResultMessage = {
                          role: "user",
                          content: [
                            {
                              type: "tool_result",
                              is_error: false,
                              content: "Question validated successfully",
                              tool_use_id: streamingMessageId.current,
                            },
                          ],
                        };
                      }
                      break;
                    }

                    case "generateArtifact": {
                      if (parsedJson) {
                        toolUseMessage = {
                          role: "assistant",
                          content: [
                            {
                              type: "tool_use",
                              id: streamingMessageId.current,
                              name: toolUseName.current,
                              input: parsedJson,
                            },
                          ],
                        };

                        toolResultMessage = {
                          role: "user",
                          content: [
                            {
                              type: "tool_result",
                              is_error: false,
                              content: "Artifact generated successfully",
                              tool_use_id: streamingMessageId.current,
                            },
                          ],
                        };
                      }
                      break;
                    }
                  }

                  if (toolUseMessage && toolResultMessage) {
                    setMessages((prev) => [
                      ...prev.map((msg) => ({
                        role: msg.role,
                        content: msg.content,
                      })),
                      toolUseMessage,
                      toolResultMessage,
                    ]);

                    handleStream([
                      ...messageHistory.map((msg) => ({
                        role: msg.role,
                        content: msg.content,
                      })),
                      toolUseMessage,
                      toolResultMessage,
                    ]);
                  }
                } catch (e) {
                  console.error("Failed to parse final JSON buffer:", e);
                }
              }
            }
          },
          onFinished: () => {
            setIsStreaming(false);
            setIsToolExecuting(false);
            streamingMessageId.current = "";
            jsonBuffer.current = "";
          },
        });
      } catch (error) {
        console.error("Failed to stream:", error);
        setIsStreaming(false);
        setIsToolExecuting(false);
        streamingMessageId.current = "";
        jsonBuffer.current = "";
      }
    },
    [question],
  );

  const handleSubmit = useCallback(async () => {
    if (!currentInput.trim() || isStreaming) return;

    const userMessage: Anthropic.Messages.MessageParam = {
      role: "user",
      content: currentInput,
    };

    streamingMessageId.current = "";
    jsonBuffer.current = "";

    setMessages((prev) => [...prev, userMessage]);
    setCurrentInput("");
    setEditorKey((prev) => prev + 1);

    await handleStream([...messages, userMessage]);
  }, [currentInput, isStreaming, messages, handleStream]);

  return (
    <Box className="relative flex h-full flex-col">
      <Box className="flex-1 overflow-y-auto p-4">
        {messages.length === 0 ? (
          <Box className="flex h-full items-center justify-center text-center">
            <Text size="lg" className="text-gray-400 max-w-md">
              Directly edit the question on the left, or ask me to help you make
              some edits!
            </Text>
          </Box>
        ) : (
          <Box className="space-y-4">
            {messages.map((message, idx) => {
              if (
                typeof message.content !== "string" &&
                message.content.some((block) => block.type === "tool_result")
              )
                return null;
              return (
                <Box
                  key={`${message.role}-${idx}`}
                  className={`rounded-lg p-3 ${
                    message.role === "assistant"
                      ? "bg-blue-50 text-blue-900"
                      : "bg-gray-50 text-gray-900"
                  }`}
                >
                  <MessageDisplay
                    message={message}
                    onSaveQuestion={handleUpdatedQuestionSaved}
                    questionId={question.id}
                  />
                </Box>
              );
            })}
            {isToolExecuting && (
              <Box className="flex items-center gap-2 rounded-lg bg-blue-50 p-3 text-blue-900">
                <Loader size="sm" />
                <Text size="sm">Processing...</Text>
              </Box>
            )}
          </Box>
        )}
      </Box>

      <MessageInput
        onSubmit={handleSubmit}
        onChange={setCurrentInput}
        isStreaming={isStreaming}
        editorKey={editorKey}
      />
    </Box>
  );
}
