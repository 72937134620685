import { ActionIcon, Card, Select, Text, Tooltip } from "@mantine/core";
import { useFetcher } from "@remix-run/react";
import { CourseSelect } from "modules/courses/course.types";
import { KnowledgeComponentWithQuestionsAndFlags } from "modules/knowledgeComponents/knowledgeComponent.types";
import { StandardSelect } from "modules/standards/standard.types";
import { useCallback, useEffect, useState } from "react";
import { MdAdd, MdRefresh } from "react-icons/md";
import { create } from "zustand";
import { action } from "~/routes/api.public.assignmentQuestions";
import { QuestionContent } from "~/routes/teacher.assignments.$assignmentId/QuestionContent";
import { showSuccessToast } from "../Notifications/customNotifications";
import { useAssignmentContext } from "./AssignmentContext";

interface AssignmentQuestionsSearchState {
  courses: CourseSelect[];
  standards: StandardSelect[];
  fetchPublicCourses: () => Promise<void>;
  selectedCourseId: number | null;
  setSelectedCourseId: (id: number | null) => void;
  fetchStandards: (courseId: number) => Promise<void>;
  selectedStandardId: number | null;
  setSelectedStandardId: (id: number | null) => void;
  questions: KnowledgeComponentWithQuestionsAndFlags[];
  fetchQuestions: (standardId: number) => Promise<void>;
  addQuestionToAssignment: ({
    assignmentId,
    questionContent,
  }: {
    assignmentId: string;
    questionContent: string;
  }) => void;
}

export const useAssignmentQuestionsSearchStore =
  create<AssignmentQuestionsSearchState>((set) => ({
    courses: [],
    standards: [],
    fetchPublicCourses: async () => {
      try {
        const response = await fetch("/api/public/courses");
        const data = await response.json();
        const courses = data.courses;
        if (courses.length > 0) {
          set({ courses: courses, selectedCourseId: courses[0].id });
        }
      } catch (error) {
        console.error("Failed to fetch public courses:", error);
      }
    },
    selectedCourseId: null,
    setSelectedCourseId: (id) => set({ selectedCourseId: id }),
    fetchStandards: async (courseId: number) => {
      try {
        const response = await fetch(`/api/standards?courseId=${courseId}`);
        const data = await response.json();
        let update: {
          standards: StandardSelect[];
          selectedStandardId: number | null;
        } = {
          standards: data.standards,
          selectedStandardId: null,
        };
        if (data.standards.length > 0) {
          update = {
            ...update,
            selectedStandardId: data.standards[0].id,
          };
        }
        set(update);
      } catch (error) {
        console.error("Failed to fetch standards:", error);
      }
    },
    selectedStandardId: null,
    setSelectedStandardId: (id) => set({ selectedStandardId: id }),
    questions: [],
    fetchQuestions: async (standardId: number) => {
      try {
        const response = await fetch(`/api/standards/${standardId}/questions`);
        const data = await response.json();
        set({ questions: data.knowledgeComponents });
      } catch (error) {
        console.error("Failed to fetch questions:", error);
      }
    },
    addQuestionToAssignment: async ({ assignmentId, questionContent }) => {
      try {
        await fetch(`/api/public/assignmentQuestions`, {
          method: "POST",
          body: JSON.stringify({ assignmentId, questionContent }),
        });
      } catch (error) {
        console.error("Failed to add question to assignment:", error);
      }
    },
  }));

// QuestionCard component to reduce duplication in rendering
interface QuestionCardProps {
  knowledgeComponent: KnowledgeComponentWithQuestionsAndFlags;
  isAdded: boolean;
  onSave: (content: string) => void;
}

const QuestionCard = ({
  knowledgeComponent,
  isAdded,
  onSave,
}: QuestionCardProps) => {
  const questionContent = JSON.stringify(
    knowledgeComponent.originalQuestion.questionContent,
  );

  return (
    <Card key={knowledgeComponent.id} className="mb-2 shadow-sm">
      <div className="flex justify-end">
        <Tooltip label={isAdded ? "Add another copy" : "Add to assignment"}>
          <ActionIcon
            onClick={() => onSave(questionContent)}
            className={`${
              isAdded
                ? "bg-secondary-600 text-white hover:bg-secondary-700"
                : "bg-secondary-50 hover:bg-secondary-100 text-secondary-600 hover:text-secondary-700"
            } transition-colors`}
            variant={isAdded ? "filled" : "subtle"}
          >
            {isAdded ? <MdRefresh size={20} /> : <MdAdd size={20} />}
          </ActionIcon>
        </Tooltip>
      </div>
      <QuestionContent question={knowledgeComponent.originalQuestion} />
    </Card>
  );
};

// SearchForm component
interface SearchFormProps {
  courses: CourseSelect[];
  standards: StandardSelect[];
  selectedCourseId: number | null;
  selectedStandardId: number | null;
  setSelectedCourseId: (id: number | null) => void;
  setSelectedStandardId: (id: number | null) => void;
}

const SearchForm = ({
  courses,
  standards,
  selectedCourseId,
  selectedStandardId,
  setSelectedCourseId,
  setSelectedStandardId,
}: SearchFormProps) => {
  return (
    <form className="flex-shrink-0 p-4 pb-2">
      <Select
        className="mb-2"
        value={selectedCourseId?.toString()}
        onChange={(value) =>
          setSelectedCourseId(value ? parseInt(value) : null)
        }
        data={courses.map((course) => ({
          value: course.id.toString(),
          label: course.name,
        }))}
      />
      <Select
        className="mb-2"
        value={selectedStandardId?.toString()}
        onChange={(value) =>
          setSelectedStandardId(value ? parseInt(value) : null)
        }
        data={standards.map((standard) => ({
          value: standard.id.toString(),
          label: standard.name,
        }))}
      />
    </form>
  );
};

export default function AssignmentQuestionsSearch() {
  // Get everything we need from the store in one go
  const {
    courses,
    fetchPublicCourses,
    selectedCourseId,
    setSelectedCourseId,
    fetchStandards,
    selectedStandardId,
    setSelectedStandardId,
    standards,
    questions,
    fetchQuestions,
  } = useAssignmentQuestionsSearchStore();

  const { assignmentId } = useAssignmentContext();
  const fetcher = useFetcher<typeof action>();
  // Track added questions in the current session
  const [addedQuestions, setAddedQuestions] = useState<Record<string, boolean>>(
    {},
  );

  // Extract the submit question logic to avoid duplication
  const submitQuestion = useCallback(
    (content: string) => {
      fetcher.submit(
        JSON.stringify({ assignmentId, questionContent: content }),
        {
          method: "POST",
          action: "/api/public/assignmentQuestions",
          encType: "application/json",
        },
      );
    },
    [assignmentId, fetcher],
  );

  const onSave = useCallback(
    (content: string) => {
      const isAlreadyAdded = addedQuestions[content];

      if (isAlreadyAdded) {
        if (
          window.confirm(
            "This question has already been added to the assignment. Are you sure you want to add another copy?",
          )
        ) {
          submitQuestion(content);
        }
      } else {
        submitQuestion(content);

        // Mark this question as added
        setAddedQuestions((prev) => ({
          ...prev,
          [content]: true,
        }));
      }
    },
    [addedQuestions, submitQuestion, setAddedQuestions],
  );

  useEffect(() => {
    if (fetcher.data?.assignmentId) {
      showSuccessToast("Question added to assignment");
    }
  }, [fetcher.data]);

  // Fetch courses when the component mounts
  useEffect(() => {
    fetchPublicCourses();
  }, [fetchPublicCourses]);

  // when a course is selected, fetch the standards
  useEffect(() => {
    if (selectedCourseId) {
      fetchStandards(selectedCourseId);
    }
  }, [selectedCourseId, fetchStandards]);

  // when a standard is selected, fetch the questions
  useEffect(() => {
    if (selectedStandardId) {
      fetchQuestions(selectedStandardId);
    }
  }, [selectedStandardId, fetchQuestions]);

  if (!assignmentId) {
    return <div>No assignment ID found</div>;
  }

  return (
    <div className="flex flex-col h-full bg-beige-50">
      <SearchForm
        courses={courses}
        standards={standards}
        selectedCourseId={selectedCourseId}
        selectedStandardId={selectedStandardId}
        setSelectedCourseId={setSelectedCourseId}
        setSelectedStandardId={setSelectedStandardId}
      />
      <div className="flex-grow overflow-y-auto p-4">
        {questions.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <Text>No questions found</Text>
          </div>
        )}
        {questions.map((knowledgeComponent) => {
          const content = JSON.stringify(
            knowledgeComponent.originalQuestion.questionContent,
          );
          return (
            <QuestionCard
              key={knowledgeComponent.id}
              knowledgeComponent={knowledgeComponent}
              isAdded={!!addedQuestions[content]}
              onSave={onSave}
            />
          );
        })}
      </div>
    </div>
  );
}
