import { DateValue } from "@mantine/dates";
import { SubmitOptions } from "@remix-run/react";
import { AssignmentFormData } from "modules/assignments/assignment.types";
import { create } from "zustand";

// Type for the fetcher from remix-utils
interface DebouncedFetcher {
  submit: (
    formData: FormData,
    options: SubmitOptions & { debounceTimeout?: number },
  ) => void;
}

interface AssignmentFormState {
  // Form data
  initialData?: AssignmentFormData;
  action?: string;
  selectedGroups: number[];
  dueDate?: Date;
  minDate?: Date;
  isNew: boolean;

  // Actions
  setInitialData: (data?: AssignmentFormData) => void;
  setAction: (action?: string) => void;
  setSelectedGroups: (groups: number[]) => void;
  setDueDate: (date?: Date) => void;
  setMinDate: (date?: Date) => void;

  // Group selection
  toggleGroup: (groupId: number, isChecked: boolean) => void;

  // Form submission with state updates
  handleTextChange: (form: HTMLFormElement, fetcher: DebouncedFetcher) => void;
  handleCheckboxChange: (
    groupId: number,
    isChecked: boolean,
    form: HTMLFormElement,
    fetcher: DebouncedFetcher,
  ) => void;
  handleDateChange: (
    value: DateValue,
    form: HTMLFormElement,
    fetcher: DebouncedFetcher,
  ) => void;

  // Helper for form operations
  submitForm: (
    form: HTMLFormElement,
    fetcher: DebouncedFetcher,
    timeout?: number,
  ) => void;
  createFormData: (form: HTMLFormElement) => FormData;
}

export const useAssignmentFormStore = create<AssignmentFormState>(
  (set, get) => ({
    // Initial state
    selectedGroups: [],
    dueDate: undefined,
    minDate: undefined,
    isNew: true,

    // Setters
    setInitialData: (data) => {
      set({
        initialData: data,
        selectedGroups: data?.groupsAssigned?.map(Number) || [],
        dueDate: data?.dueDate ? new Date(data.dueDate) : undefined,
        isNew: data === undefined,
      });
    },

    setAction: (action) => set({ action }),
    setSelectedGroups: (groups) => set({ selectedGroups: groups }),
    setDueDate: (date) => set({ dueDate: date }),
    setMinDate: (date) => set({ minDate: date }),

    // Group selection
    toggleGroup: (groupId, isChecked) => {
      set((state) => ({
        selectedGroups: isChecked
          ? [...state.selectedGroups, groupId]
          : state.selectedGroups.filter((id) => id !== groupId),
      }));
    },

    // Create form data with proper values
    createFormData: (form) => {
      // Create a new FormData instance to modify before submission
      const formData = new FormData(form);

      // Clear any existing groupsAssigned values to avoid duplicates
      const existingEntries = Array.from(formData.entries());
      existingEntries.forEach(([key]) => {
        if (key === "groupsAssigned") {
          formData.delete(key);
        }
      });

      // Add all selected groups to the form data
      get().selectedGroups.forEach((groupId) => {
        formData.append("groupsAssigned", groupId.toString());
      });

      // Always ensure dueDate is included if we have one
      const { dueDate } = get();
      if (dueDate) {
        formData.set("dueDate", dueDate.toISOString());
      }

      return formData;
    },

    // Submit the form with the current state
    submitForm: (form, fetcher, timeout = 1000) => {
      const action = get().action;
      if (!form || !action) return;

      const formData = get().createFormData(form);

      fetcher.submit(formData, {
        debounceTimeout: timeout,
        method: "POST",
        action,
      });
    },

    // Form field handlers with atomic updates
    handleTextChange: (form, fetcher) => {
      get().submitForm(form, fetcher);
    },

    handleCheckboxChange: (groupId, isChecked, form, fetcher) => {
      // Update the state first
      get().toggleGroup(groupId, isChecked);

      // Then submit the form with the updated state
      get().submitForm(form, fetcher, 0);
    },

    handleDateChange: (value, form, fetcher) => {
      // Update the state first
      const newDate = value ? new Date(value) : undefined;
      set({ dueDate: newDate });

      // Then submit the form with the updated state
      get().submitForm(form, fetcher);
    },
  }),
);
