import { Box, Button, Divider, Text } from "@mantine/core";
import { useFetcher, useOutletContext } from "@remix-run/react";
import { AssignmentQuestion } from "modules/questions/question.types";
import { useEffect, useState } from "react";
import { TbRobot, TbTrash } from "react-icons/tb";
import { useDebounceFetcher } from "remix-utils/use-debounce-fetcher";
import {
  showErrorToast,
  showSuccessToast,
} from "~/components/Notifications/customNotifications";
import { QuestionContentEditor } from "~/components/QuestionContentEditor/QuestionContentEditor";
import { OutletContext } from "~/root";
import { action as deleteAction } from "../api.questions.$questionId.delete";
import { action } from "../api.questions.$questionId.updateContent";
import QuestionEditorAssistant from "./QuestionEditorAssistant";
import QuestionTypeDropdown from "./QuestionTypeDropdown";

interface QuestionEditorProps {
  item: AssignmentQuestion;
  index: number;
}

export default function QuestionEditor({ item, index }: QuestionEditorProps) {
  const { user } = useOutletContext<OutletContext>();
  const fetcher = useDebounceFetcher<typeof action>();
  const deleteFetcher = useFetcher<typeof deleteAction>();
  const [opened, setOpened] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (deleteFetcher.data) {
      if (deleteFetcher.data.success) {
        setDeleted(true);
        showSuccessToast("Question deleted successfully.", "bottom-left");
      } else {
        showErrorToast(
          "Failed to delete question. Please try again.",
          "bottom-left",
        );
      }
    }
  }, [deleteFetcher.data]);

  const handleEditorChange = (
    value: string,
    field: "questionText" | "explanation",
  ) => {
    fetcher.submit(
      { [field]: value },
      {
        method: "POST",
        encType: "application/json",
        action: `/api/questions/${item.question.id}/updateContent`,
        debounceTimeout: 1000,
      },
    );
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      deleteFetcher.submit(
        {},
        {
          method: "POST",
          action: `/api/questions/${item.question.id}/delete`,
        },
      );
    }
  };

  const handleClose = () => {
    setOpened(false);
  };

  if (deleted) {
    return null;
  }

  return (
    <Box className="bg-white pt-1 pb-4 rounded-md mb-12 shadow-md">
      <Box className="flex flex-row justify-between px-4 pb-5 pt-5">
        <Text className="text-md font-bold">Question {index + 1}</Text>
        <TbTrash
          size={20}
          className="cursor-pointer hover:text-red-500"
          onClick={handleDelete}
        />
      </Box>
      <Divider />
      <Box className="py-2 px-4">
        <Box className="flex flex-row items-center gap-3 pb-2">
          <QuestionTypeDropdown question={item.question} />
          {user && (
            <Button
              variant="light"
              color="primary"
              size="xs"
              leftSection={<TbRobot size={16} />}
              onClick={() => setOpened(true)}
            >
              AI Assist
            </Button>
          )}
        </Box>
        <QuestionContentEditor
          key={opened ? "open" : "closed"} // forces re-render so that updates made in modal show up
          question={item.question}
          onContentChange={handleEditorChange}
        />
      </Box>
      <QuestionEditorAssistant
        question={item.question}
        opened={opened}
        onClose={handleClose}
        onContentChange={handleEditorChange}
      />
    </Box>
  );
}
