import { globalsCSS } from "./globals";
import { tailwindConfig } from "./tailwind";
import { tsconfigJson } from "./tsconfig";
import { utilsConfig } from "./utils";

export const sandpackConfig = {
  "/styles/globals.css": globalsCSS,
  "/lib/utils.ts": utilsConfig,
  "/tailwind.config.js": tailwindConfig,
  "/tsconfig.json": tsconfigJson,
};
